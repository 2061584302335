/* You can add global styles to this file, and also import other style files */
.infinite-viewport {
	height: 70vh;
	width: 100%;
}
quill-editor{
    width: 100%;
}
.ql-editor{
	min-height: 300px;
}
.mat-expansion-panel{
	margin-bottom: 5px !important;
}
.mat-datepicker-container{
	display: flex;
	overflow: hidden;
}